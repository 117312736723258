import request from '@/utils/request'
export function getForumList(data) {
    return request({
        url:'/system/forum/forum/list',
        method:'get',
        params:data
    })
}

//发布论坛
export function postingForums(data) {
    return request({
        url: "/system/forum/forum",
        method: "post",
        data: data,
    });
}


// 新增点赞
export function addLike(data) {
    return request({
        url: "/system/forum/upvote",
        method: "post",
        data: data,
    });
}

// 删除点赞
export function deletaLike(data) {
    return request({
        url: "/system/forum/upvote/" + data,
        method: "delete",
    });
}


//获取评论详情
export function getForumDetail(data) {
    return request({
        url:'/system/forum/forum/'+data,
        method:'get'
    })
}


//获取个人主页数据，点赞数
export function getUserOpvite(data) {
    return request({
        url:'/system/forum/forum/personalFile',
        method:'get',
        params:data
    })
}


//新增评论
export function addCommit(data) {
    return request({
        url:'/system/forum/comment',
        method:'post',
        data:data
    })
}

//查询评论列表
export function getForumCommitList(data) {
    return request({
        url:'/system/forum/comment/list',
        method:'get',
        params:data
    })
}

//查询二级评论列表
export function getTwoForumCommitList(data) {
    return request({
        url:'/system/forum/comment/listSecondaryComment/'+data,
        method:'get',
        // params:data
    })
}


//新增试卷
export function addExamPaper(data) {
    return request({
        url:'/system/summary',
        method:'post',
        data
    })
}
