import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from "@/layout/index.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: layout,
    children:[
      {//题库
        path:'questionBank',
        name:'questionBank',
        component:()=>import('@/views/questionBank')
      },
      {//试卷
        path:'examPaperLibrary',
        name:'examPaperLibrary',
        component:()=>import('@/views/examPaperLibrary')
      },
      {//试卷详情
        path:'examPaperLibraryDetail',
        name:'examPaperLibraryDetail',
        component:()=>import('@/views/examPaperLibrary/detail.vue')
      },
      {//论坛
        path:'forum',
        name:'forum',
        component:()=>import('@/views/forum')
      },
      {//论坛详情
        path:'forumDetail',
        name:'forumDetail',
        component:()=>import('@/views/forum/detailIndex.vue')
      },
      {//学情分析
        path:'analysisOfStudents',
        name:'analysisOfStudents',
        component:()=>import('@/views/analysisOfStudents')
      },
      {//批阅详情
        path:'lookAnalysis',
        name:'lookAnalysis',
        component:()=>import('@/views/analysisOfStudents/lookAnalysis.vue')
      },
      {//个人档案
        path:'personalProfile',
        name:'personalProfile',
        component:()=>import('@/views/personalProfile')
      },

      {//个人档案详情
        path:'personalDetail',
        name:'personalDetail',
        component:()=>import('@/views/personalProfile/detail.vue')
      },
      {//个人档案详情1
        path:'personalDetail1',
        name:'personalDetail1',
        component:()=>import('@/views/personalProfile/detail1.vue')
      },
      {//个人中心
        path:'personalCenter',
        name:'personalCenter',
        component:()=>import('@/views/personalCenter/index.vue')
      },
      {//个人学情分析
        path: 'lookAnalyRecordDetail',
        name:'lookAnalyRecordDetail',
        component:()=>import('@/views/analysisOfStudents/lookAnalyRecordDetail.vue')
      },
      {//自定推荐题
        path: "generateRecommendations",
        name:'generateRecommendations',
        component:()=>import('@/views/analysisOfStudents/generateRecommendations/index.vue')
      }


    ]
  },

  {
    path:'/login',
    name:'login',
    component:()=>import('@/views/login.vue')
  },

  {
    path:'/register',
    name:'register',
    component:()=>import('@/views/register.vue')
  }
]


// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}



const router = new VueRouter({
  routes
})



export default router
