import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from '@/api'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false


import mathJax from "./utils/mathJax.js";
Vue.prototype.mathJax = mathJax;



Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
