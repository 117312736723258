import {Message} from "element-ui";

const state = {
    packageData: [
        {
            type: '第一大题',
            list: []
        }
    ],//用于存放试题包数据
}

const mutations = {
    // 添加题目到试题包，同时检查是否已存在
    ADD_PACKAGEDATA(state, val) {

        console.log('添加',val)
        // 查找匹配的题型并添加题目，如果题目不存在则添加
        state.packageData.some(ele => {
            if (ele.type === val.type) {
                // 检查列表中是否已经存在相同的题目
                const existingItemIndex = ele.list.findIndex(item => item.itemBankId === val.itemBankId);
                if (existingItemIndex === -1) {
                    // 如果不存在，则添加题目
                    ele.list.push(val);
                } else {
                    console.log('题目已存在');
                    setTimeout(() => {
                        Message.warning('题目已存在')
                    }, 100)
                }
                return true; //  停止循环
            }
        });
    },

// 从试题包中删除题目
    DELETE_PACKAGEDATA(state, val) {
        console.log('删除题目', val)
        // 查找匹配的题型并删除题目
        state.packageData.forEach(ele => {
            if (ele.type === val.type) { // 确保只检查匹配的题型
                console.log(ele.type,val.type)
                const index = ele.list.findIndex(item => {
                    console.log('匹配',item.itemBankId , val.itemBankId)
                     return  item.itemBankId === val.itemBankId
                });
                console.log(index)
                if (index !== -1) {
                    ele.list.splice(index, 1); // 从当前题型中删除题目
                }
            }
        });
    },
    //清空试题
    CLEAR_PACKAGEDATA(state) {
        state.packageData = [
            {
                type: '第一大题',
                list: []
            }
        ]
    },

    //添加大题
    ADD_BIGBANK(state, val) {
        let obj = {
            type: val,
            list: []
        }
        state.packageData.push(obj)
    },
};

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
