// let isMathjaxConfig = false; // ⽤于标识是否配置
// const initMathjaxConfig = () => {
//   if (!window.MathJax) {
//     return;
//   }
//   window.MathJax = {
//     tex: {
//       inlineMath: [
//         ["$","$"],
//         ["\\(", "\\)"],
//       ], // ⾏内公式选择符
//       displayMath: [
//         ["$$", "$$"],
//         ["$", "$"],
//         ["\\[", "\\]"],
//       ], // 段内公式选择符
//     },
//     options: {
//       skipHtmlTags: [
//         "script",
//         "noscript",
//         "style",
//         "textarea",
//         "pre",
//         "code",
//         "a",
//       ], // 避开某些标签
//       ignoreHtmlClass: "tex2jax_ignore",
//       processHtmlClass: "tex2jax_process",
//     },
//   };
//   isMathjaxConfig = true; // 配置完成，改为true
// };
// const TypeSet = async function (elementId) {
//   if (!window.MathJax) {
//     return;
//   }
//   window.MathJax.startup.promise = window.MathJax.startup.promise
//     .then(() => {
//       return window.MathJax.typesetPromise();
//     })
//     .catch((err) => console.log("Typeset failed: " + err.message));
//   return window.MathJax.startup.promise;
// };
// export default {
//   isMathjaxConfig,
//   initMathjaxConfig,
//   TypeSet,
// };


let isMathjaxConfig = {
  bool: false
}// 用于标识是否配置
const initMathjaxConfig = () => {
  if (!window.MathJax) {
    return
  }
  // MathJax.Hub.Config({
  //   showProcessingMessages: false, // 关闭js加载过程信息
  //   messageStyle: 'none', // 不显示信息
  //   jax: ['input/TeX', 'output/HTML-CSS'],
  //   tex2jax: {
  //     inlineMath: [['$', '$'], ['\\(', '\\)'] ], // 行内公式选择符
  //     displayMath: [['$$', '$$'], ['\\[', '\\]']], // 段内公式选择符
  //     skipTags: ['script', 'noscript', 'style', 'textarea', 'pre', 'code', 'a'], // 避开某些标签
  //     ignoreClass:'imgRight',
  //     processEscapes: true
  //   },
  //   'HTML-CSS': {
  //     availableFonts: ['STIX', 'TeX'], // 可选字体
  //     showMathMenu: false // 关闭右击菜单显示
  //   },
  //   // TeX: {  
  //   //   extensions: ["AMSmath.js", "AMSsymbols.js"] // 加载 amsmath 扩展  
  //   // },  
  // TeX: {
  //   equationNumbers: { autoNumber: ['AMS'], useLabelIds: true },
  //   extensions: ['AMSmath.js', 'AMSsymbols.js', 'extpfeil.js', 'mhchem.js'],
  // },
  // })
  MathJax.Hub.Config({
    skipStartupTypeset: true, // 避免页面一开始渲染全部公式
    showProcessingMessages: false, // 关闭js加载过程信息
    messageStyle: "none", // 不显示信息
    jax: ["input/TeX", "output/HTML-CSS"],
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
        ['\[\begin{array}*','*\end{array}\]']
      ],
      skipTags: [
        "script",
        "noscript",
        "style",
        "textarea",
        "pre",
        "code",
        "a",
      ], // 避开某些标签
      ignoreClass: "imgRight",
      processEscapes: true,
    },
    "HTML-CSS": {
      availableFonts: ["STIX", "TeX"], // 可选字体
      showMathMenu: false, // 关闭右击菜单显示
    },
    TeX: {
      equationNumbers: { autoNumber: ["AMS"], useLabelIds: true },
      extensions: ["AMSmath.js", "AMSsymbols.js", "extpfeil.js", "mhchem.js"],
    },
  });
  isMathjaxConfig.bool = true // 配置完成，改为true
}
const MathQueue = function (elementId) {
  if (!window.MathJax) {
    return
  }
  window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, document.getElementById(elementId)])
}
export default {
  isMathjaxConfig,
  initMathjaxConfig,
  MathQueue
}

