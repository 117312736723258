
<template>
<div class="appBox">

  <navbar></navbar>
  <sidebar></sidebar>
  <div class="appBox-over">
    <div class="appBox-over-Box">
    <router-view></router-view>
    </div>

  </div>

</div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import Sidebar from "@/components/sidebar.vue";

export default {
  name: "index",
  components: {Sidebar, navbar},
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.appBox{
  height: 100vh;
  width: 100vw;


  &-over{
    height:calc( 100% - 102px);
    overflow: auto;
    background-color: #f3f5f9;
    &-Box{
      width: 70%;
      min-width: 985px;
      margin: 0 auto;
    }
  }
}
</style>
