
<template>
<div class="navberBox">
      <div class="navberBox-content">
        <div class="navberBox-content-left">
          oneonone
        </div>
        <div class="navberBox-content-right">
          <div>历史下载</div>
          <div> <span class="el-icon-bell"></span> 消息</div>
          <div @click="goUserInfo">  <el-avatar size="small" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> 我的</div>
        </div>
      </div>

</div>
</template>
<script>
export default {
  name: "navbar",
  methods:{
    goUserInfo(){
      this.$router.push('/personalCenter')
    }
  }
}
</script>

<style scoped lang="scss">

  .navberBox{
    height: 42px;
    background-color: white;
    &-content{
      width: 70%;
      min-width: 985px;
      height: 42px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left{
        color: #61b8c1;
        font-size: 25px;
      }
      &-right{
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(0,0,0,0.7);
        >div{
          display: flex;
          align-items: center;
          margin-left: 15px;
          cursor: pointer;
          >span{
            margin-right: 5px;
          }
        }
      }
    }
  }

</style>
