<template>
  <div id="app">
    <router-view/>


    <div v-if="visible" @click="visible=false" style="height: 100vh;width: 100vw;position:fixed;top: 0;left: 0;z-index: 1;"></div>

<!--    <div v-draggable class="draggPackage">-->
    <div v-if="badgeBox"  class="draggPackage" @click="visible=!visible" :style="{right:visible?'400px':'0px'}">
      <el-badge :value="packageTotal" :hidden="packageTotal==0" class="item">
        <img class="draggPackage-icon" src="@/assets/packageIcon.png" alt="">
      </el-badge>
      <div class="draggPackage-txt">试题包</div>

    </div>


    <!--  试题包弹框  -->
    <div   :style="{width:visible?'400px':'0px'}" class="bankDilogBox">
      <testQuestionPackage></testQuestionPackage>
    </div>
    <!--  试题包弹框  -->


  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import {watermark} from "watermark-dom";
import testQuestionPackage from "@/components/testQuestionPackage/index.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    navbar,
    testQuestionPackage
  },
  computed:{
    ...mapGetters(['packageTotal']),
    badgeBox: function () {
      //题库大厅
      let route = this.$route
      console.log(route)
      //白名单
      let whiteList = ['/questionBank','/analysisOfStudents']
      let bb = whiteList.some(ele => ele == route.path)
      console.log(bb)
      return bb
    }
  }

  ,


  mounted() {
    console.log(watermark)
    // this.$store.dispatch('GET_INFO')

  },
  data() {
    return {
      visible: false
    }
  },
  directives: {
    draggable: {
      bind(el, binding, vnode) {
        let offsetX, offsetY;
        let isDragging = false;

        const onMouseDown = (e) => {
          // 记录鼠标按下时的位置
          offsetX = e.clientX - el.offsetLeft;
          offsetY = e.clientY - el.offsetTop;
          isDragging = true;

          // 阻止默认行为（如选择文本）
          e.preventDefault();

          // 在文档上添加鼠标移动和松开事件监听器
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        };

        const onMouseMove = (e) => {
          if (isDragging) {
            // 计算新位置
            const newLeft = e.clientX - offsetX;
            const newTop = e.clientY - offsetY;

            // 更新元素位置
            el.style.left = `${newLeft}px`;
            el.style.top = `${newTop}px`;
          }
        };

        const onMouseUp = () => {
          isDragging = false;

          // 移除文档上的鼠标移动和松开事件监听器
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        };

        // 为元素添加鼠标按下事件监听器
        el.addEventListener('mousedown', onMouseDown);

        // 在指令解绑时清理事件监听器
        vnode.context.$on('hook:beforeDestroy', () => {
          el.removeEventListener('mousedown', onMouseDown);
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        });
      },
    }
  }

}
</script>

<style lang="scss">


.draggPackage {
  width: 58px;
  height: 58px;
  border-radius: 10px;
  background: #FFFFFF;
  position: absolute;
  right: 0px;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  transition: 1s;
  z-index: 9999;
  &-icon{
    width: 22px;
    height: 22px;
  }
  &-txt{
    font-size: 12px;
    color: #9E9E9E;
  }



}

.bankDilogBox {
  height: 700px;
  //max-height: 700px;
  width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: -2px 1px 7px #ccc;
  overflow: hidden;
  transition: 1s;
  z-index: 9999;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
