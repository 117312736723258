import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import store from '@/store'
import errorCode from "@/utils/errorCode";
import { saveAs } from 'file-saver'

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = {show: false};



// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: store.state.baseUrl,
    // 超时
    timeout: 1000000
})

// request拦截器
service.interceptors.request.use(config => {

        config.headers['Authorization'] = 'Bearer ' + (store.state.token||localStorage.getItem('token') )// 让每个请求携带自定义token 请根据实际情况自行修改
        // config.headers['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjA4N2U4MGJhLTE1ZjMtNDY4My1hNGU4LWQyZjcyYTg0NTg4MSJ9.hHxYjV6ALJxGsYYzHzZ6h8PUvqs1RLLPbhf4hafQ6r81HTS5sLYXJjgkWoCavYto0bYuLDLyVo0PvJxNNpEM7g"



    return config
}, error => {
    // console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;

        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
            return res.data
        }
        if (code === 401) {
            if (!isRelogin.show) {
                isRelogin.show = true;
                MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
                    isRelogin.show = false;
                    store.dispatch('loginOut').then(() => {
                        location.href = '#/login';
                    })
                }).catch(() => {
                    isRelogin.show = false;
                });
            }
            return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
        } else if (code === 500) {
            // Message({ message: msg, type: 'error' })
            // return Promise.reject(new Error(msg))
            return Promise.reject(res.data)
        } else if (code === 601) {
            Message({ message: msg, type: 'warning' })
            return Promise.reject('error')
        }  else if (code === 666) {
            Message({ message: '当前筛选项未查询到数据', type: 'warning' })
        } else if (code !== 200) {
            return Promise.reject('error')
        } else {
            return res.data
        }





    },
    error => {
        // console.log('err' + error)
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({message: message, type: 'error', duration: 5 * 1000})


        console.log('超市')
        console.error(error)

        return Promise.reject(error)
    }
)

// 通用下载方法
export function download(url, params, filename, config) {
    downloadLoadingInstance = Loading.service({
        text: "正在下载数据，请稍候",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
    })
    return service.post(url, params, {
        // transformRequest: [(params) => {
        //     return tansParams(params)
        // }],
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        responseType: 'blob',
        ...config
    }).then(async (data) => {
        const isBlob = blobValidate(data);
        if (isBlob) {
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            // const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            // Message.error(errMsg);
        }
        downloadLoadingInstance.close();
    }).catch((r) => {
        console.error(r)
        Message.error('下载文件出现错误，请联系管理员！')
        downloadLoadingInstance.close();
    })
}
function blobValidate(data) {
    return data.type !== 'application/json'
}


export default service
