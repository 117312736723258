<template>
  <div class="box">
    <div class="title">已加 <span>{{ packageTotal }}</span> 道题</div>


    <div class="innerBox">

      <div class="typeNumBox">
        <div v-for="item in packageData">
          {{ item.type }}：{{ item.list.length }}
        </div>
        <div @click="addBigBank">添加大题</div>
      </div>


      <div v-for="(item,index) in packageData" :key="index" class="bigBankItem">
        <!--              <div class="bigBankItem-title">{{ bigIndex(index + 1) }}、{{ item.type }}</div>-->
        <div class="bigBankItem-title">
          {{ bigIndex(index + 1) }}、
          <el-input v-model="item.type"></el-input>
        </div>
        <div class="bigBankItem-remark" style="margin-top: 10px">
          <el-input v-model="item.reserve15"></el-input>
        </div>


        <draggable v-model="item.list" animation="500" group="groupA" style="min-height: 100px">
          <transition-group>
            <div v-for="(innitem,innerIndex) in item.list" :key="innitem.itemBankId" class="bigBankItem-content">
              <div v-html="`${innerIndex+1}、${innitem.testItemContent}`"></div>
              <div @click="delItem(innitem)">移出此题</div>
            </div>
          </transition-group>
        </draggable>


      </div>
    </div>

    <div class="boxBottom">
      <el-button icon="el-icon-delete" type="text" @click="clearPackage">清空试卷</el-button>
      <div style="display: flex">
        <el-button style="background-color: #61B8C1;font-size: 12px;color: white;" type="primary" @click="goExamPaperLibraryDetail">进入试卷</el-button>
<!--        <el-button style="background-color: #61B8C1;font-size: 12px;color: white;" type="primary" @click="savePaper(0)">-->
<!--          加入暂存库-->
<!--        </el-button>-->
<!--        <el-button style="background-color: #61B8C1;font-size: 12px;color: white;" type="primary" @click="savePaper(1)">-->
<!--          发布至大厅-->
<!--        </el-button>-->
        <!--        <el-button style="background-color: #61B8C1;font-size: 12px;color: white;" type="primary" @click="goExamPaperLibraryDetail">进入试卷</el-button>-->
      </div>
    </div>

    <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisibleVue"
        append-to-body
        :title="deletePaper?'编辑':'发布'"
        width="30%">
      <el-form :model="form" label-position="left" label-width="80px">
        <el-form-item label="试卷名称">
          <el-input v-model="form.examinationPaperSummaryName"></el-input>
        </el-form-item>
        <el-form-item label="试卷难度">
          <!--          <el-input v-model="form.region"></el-input>-->
          <el-select v-model="form.reserve5" placeholder="请选择" style="width: 100%">
            <el-option
                label="简单"
                value="简单">
            </el-option>
            <el-option
                label="普通"
                value="普通">
            </el-option>
            <el-option
                label="困难"
                value="困难">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷分类">
          <!--          <el-input v-model="form.type"></el-input>-->

          <el-cascader
              v-model="form.testPaperClassificationId"
              :options="classList"
              :props="defaultProps"
          ></el-cascader>

        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleVue = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
    </el-dialog>


  </div>
</template>
<script>

//导入draggable组件
import draggable from 'vuedraggable'
import {mapGetters} from "vuex";
import {addExamPaper} from "@/api/forum";

export default {
  name: "index",
  //注册draggable组件
  components: {
    draggable,
  },
  computed: {
    ...mapGetters(['packageData', 'packageTotal','deletePaper'])

  },
  watch:{
    deletePaper:{
      handler:function (n) {
        console.log('触发回显',n)
        if(n){
          this.form.examinationPaperSummaryName=n.examinationPaperSummaryName
          this.form.reserve5=n.reserve5
          this.form.testPaperClassificationId=n.testPaperClassificationId
        }
      },
      deep:true,
      immediate:true
    }
  }

  ,
  data() {
    return {
      dialogVisibleVue: false,
      form: {},
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      classList: [],

      groupA: {
        name: 'big',
      },
      packageDataVue: []
    }
  }

  ,


  updated() {
    //判断是否初始配置，若无则配置。
    if (!this.mathJax.isMathjaxConfig.bool) {
      this.mathJax.initMathjaxConfig();
    }
    this.$nextTick((_) => {
      //传入组件id，让组件被MathJax渲染
      this.mathJax.MathQueue("questionList-page");
    });
  },

  mounted() {
  }
  ,
  methods: {
    addBigBank() {
      this.$store.commit('testQuestionPackage/ADD_BIGBANK', '大题名称' + this.packageData.length)
    },
    bigIndex(index) {
      switch (index) {

        case  1:
          return '一'
          break
        case  2:
          return '二'
          break
        case  3:
          return '三'
          break
        case  4:
          return '四'
          break
        case  5:
          return '五'
          break
        case  6:
          return '六'
          break
        case  7:
          return '七'
          break
      }

    },
    delItem(item) {
      this.$store.commit('testQuestionPackage/DELETE_PACKAGEDATA', item)
    },
    clearPackage() {
      this.$store.commit('testQuestionPackage/CLEAR_PACKAGEDATA')
    },
    goExamPaperLibraryDetail(){
      this.$router.push('/examPaperLibraryDetail?type=lock')
    },
    onEnd() {
    },
    onStart() {
    },
    handleClose(done) {
      done()
    },
    savePaper(row) {
      this.form['reserve7'] = row
      this.dialogVisibleVue = true
    },
    submit() {
      //计算小题题目
      let questions = 0
      this.packageData.forEach(ele => {
        questions = questions + ele.list.length
      })

      //计算大题题目
      let bigProblem = 0
      this.packageData.forEach(ele => {
        if (ele.list.length != 0) {
          bigProblem++
        }
      })


      //小题集合
      let examinationPaperDetailsList = []
      this.packageData.map(ele => {
        if (ele.list != 0) {
          ele.list.map(innerEle => {
            examinationPaperDetailsList.push({
              itemBankId: innerEle.itemBankId,
              bigProblem: ele.type,
              reserve15: ele.reserve15
            })
          })
        }

      })

      console.log('examinationPaperDetailsList', examinationPaperDetailsList)


      let obj = {

        ...this.form,
        questions,
        bigProblem,
        examinationPaperDetailsList,
        reserve4: 1


      }

      let id = this.$store.state.testQuestionPackage.deletePaper.examinationPaperSummaryId
      if (id) {
        deleteExamPaper(id).then(res => {
          this.$store.commit('testQuestionPackage/SET_DELETEPAPERID', null)
        })
      }


      console.log('obj', obj)
      addExamPaper(obj).then(res => {
        console.log(res)
        this.dialogVisibleVue = false
        this.$store.commit('testQuestionPackage/CLEAR_PACKAGEDATA')
        this.form = {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 700px;

  //max-height:50vh;
  //width: 100%;
  width: 400px;
  text-align: left;
  display: flex;
  flex-direction: column;


  .title {
    padding: 16px 20px;
    margin-top: 10px;
    border-bottom: 1px dashed #ccc;
    font-size: 14px;
    color: #333;

    span {
      color: #008593;
    }
  }

  .typeNumBox {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 20px;

    > div {
      padding: 3px 12px;
      background: rgba(97, 184, 193, 0.4);
      font-size: 12px;
      color: #008593;
      margin-right: 6px;
      border-radius: 6px;
      line-height: 23px;
      //height: 23px;
      margin-bottom: 10px;


    }
  }

  .innerBox {
    overflow: auto;
    flex: 1;
  }


  .bigBankItem {
    margin: 20px;
    //height: 100%;
    min-height: 100px;

    &-title {
      font-size: 12px;
      font-weight: 400;
      color: #333;
      display: flex;
      align-items: center;
    }

    &-content {
      font-size: 14px;
      margin: 16px 0;
      display: flex;
      flex-direction: column;

      > div:nth-of-type(1) {
        margin-bottom: 16px;
        color: #333;
      }

      > div:nth-of-type(2) {
        width: 68px;
        height: 23px;
        border-radius: 6px;
        background: #FFFFFF;
        box-sizing: border-box;
        /* 测试主题 */
        border: 1px solid #61B8C1;
        align-self: flex-end;
        line-height: 23px;

        opacity: 1;

        font-size: 12px;
        text-align: center; /* 浏览器可能不支持 */

        /* 测试主题 */
        color: #61B8C1;
        font-weight: 400;


      }

    }
  }


  .boxBottom {
    display: flex;
    justify-content: space-between;
    padding: 9px 16px;
    border-top: 1px solid #eee;

    .el-button {
      height: 28px;
      width: 92px;
      padding: 0;
    }
  }

}


.bigBankItem-remark {
  ::v-deep .el-input__inner {
    border: none;
  }

  ::v-deep .el-input__inner:hover {
    border: 1px solid #DCDFE6;
  }
}
</style>
