import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import {getUserInfo} from "@/api";
import { Message } from 'element-ui';
import testQuestionPackage from "@/store/module/testQuestionPackage";

import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
    state: {
        token: '',
        // baseUrl: 'http://192.168.1.2:8080',
        baseUrl: "https://www.51150.shop/api/",
        userInfo: {}
    },
    getters: {
        token: state => {
            return state.token
        },
        packageData:state=>{
            return state.testQuestionPackage.packageData
        },
        packageTotal:(state)=>{
            let num =0
            state.testQuestionPackage.packageData.forEach(ele=>{
                num=num+ele.list.length
            })
            return num
        },
        baseUrl:(state)=>{
            return state.baseUrl
        },
        userId:(state)=>{
            console.log('getter',state.userInfo)
                return state.userInfo.userId

        },
        userInfo:(state)=>{
            return state.userInfo
        }

    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token',token)
        },
        SET_USERINFO(state, value) {
            state.userInfo = value
            // localStorage.setItem('userInfo',value)
        }
    },
    actions: {
        GET_INFO({state,commit}){


          return new Promise((resolve, reject)=>{
            getUserInfo().then(res=>{
              if(res.roles.includes('student')){

                // state.userInfo=res.user
                  commit('SET_USERINFO',res.user)
                resolve(res)

              }else{
                  Message({
                  type: 'error',
                  message: `登录失败`
                });
                reject('登录失败')
              }

            }).catch(err=>{

                reject('登录失败')
            })
          })



        },
        loginOut({state,commit}){
           new Promise((resolve, reject) => {
               commit('SET_TOKEN',null)
               commit('SET_USERINFO',null)
               localStorage.clear()
               resolve()
           })
        },
        GET_STORGUSERINFO:async (state)=>{
            if(Object.keys(state.userInfo).length==0){

                await this.GET_INFO()
                return state.userInfo
            }else{
                return  state.userInfo
            }
        }
    },

    plugins: [createPersistedState({
        paths:['userInfo','testQuestionPackage.packageData']
    })],
    modules: {
        testQuestionPackage
    }
})
